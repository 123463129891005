import { useEffect, useState } from "react";
import Logo from "../components/Logo";
import { ChevronLeftIcon, Cog6ToothIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { IconCheck, IconExternalLink, IconSpinner } from "../components/icons";
import { createSearchParams } from "react-router-dom";
import { patent_family, trademark_family } from "../data";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { truncate } from "../utils/strings";
import { ArrowUpTrayIcon, ChevronDownIcon, MagnifyingGlassIcon, CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { htmlEncode } from "../documents/encoding";


//import '@microsoft/office-js';
function loadOfficeJsScript() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

const headerRowStyle = "flex flex-row items-center justify-between gap-2 p-4 bg-pcx-200";

type Message = {
  type: "info" | "error",
  message: string,
  payload?: string,
}

function MessageView({message, close}: {message: Message, close: () => void}) {
  if (!message) return null;

  return (
    <div className="p-2 w-full bg-pcx-100">
      <div
        className={`${
          message.type === "info"
            ? "bg-pcx-200 text-pcx-900 border-pcx-300/20"
            : "bg-red-200 text-red-950 border-red-300/20"
        } rounded-lg shadow-sm p-3 text-xs flex flex-row items-center gap-2 border`}
      >
        <div onClick={() => message?.payload && navigator.clipboard.writeText(message.payload)}>
          {message.type === "info" ? (
            <InformationCircleIcon className="size-5" />
          ) : (
            <ExclamationTriangleIcon className="size-5" />
          )}
        </div>
        <div className="grow whitespace-pre-wrap">{message.message}</div>
        <button type="button" title="Close" onClick={close}>
          <XCircleIcon className="size-5" />
        </button>
      </div>
    </div>
  );
}

export function OutlookPlugin() {
  const [isReady, setIsReady] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const [apiToken, setApiToken] = useState(undefined as string | undefined);

  const [message, setMessage] = useState(undefined as Message | undefined)

  useEffect(() => {
    loadOfficeJsScript()
      .then(() => {
        Office.onReady(async () => {
          setIsReady(true);
          console.log("Office.js is ready!");
          setApiToken(Office.context.roamingSettings.get("api-key"))
          await ensureCategoryExists()
            .catch(err => setMessage({ type: "error", message: err + " "}));
        });
      })
      .catch((err) => console.error("Failed to load Office.js:", err));
  }, []);

  function saveSettings(newToken: string) {
    setApiToken(newToken);
    Office.context.roamingSettings.set("api-key", newToken);
    Office.context.roamingSettings.saveAsync();
  }

  if (!isReady) {
    return <div className="flex flex-col justify-center items-center h-screen">
      <IconSpinner className="animate-spin size-8 text-pcx-500" />
    </div>;
  }



  return !showSettings ? (
    <div className="flex flex-col h-full">
      <div className={headerRowStyle}>
        <div className="w-10" /> {/* empty */}
        <h1 className="self-center">
          <a href="/" target="_blank" rel="noreferrer" title="Patent Cockpit">
            <span className="sr-only">Patent Cockpit</span>{" "}
            <Logo className="h-8" />
          </a>
        </h1>
        <button
          onClick={() => setShowSettings(true)}
          aria-label="Settings"
          type="button"
          className="p-1 text-pcx-500 hover:text-pcx-700"
        >
          <Cog6ToothIcon className="size-6" />
        </button>
      </div>
      <MessageView message={message} close={() => setMessage(undefined)} />
      <div className="p-4 w-full">
        {apiToken ? (
          <SavePlugin {...{ apiToken, setMessage, goToSettings: () => setShowSettings(true) }} />
        ) : (
          <Welcome onSubmit={saveSettings} />
        )}
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className={headerRowStyle}>
        <button
          onClick={() => setShowSettings(false)}
          aria-label="Home"
          type="button"
          className="p-1 text-pcx-500 hover:text-pcx-700"
        >
          <ChevronLeftIcon className="size-6" />
        </button>
        <h1 className="text-xl font-light text-slate-800">Settings</h1>
        <div className="w-10" /> {/* empty */}
      </div>
      <div className="px-4 py-10">
        <SettingsForm onSubmit={saveSettings} apiToken={apiToken} canDelete />
      </div>
    </div>
  );
}

async function searchForReferences({ query, apiToken, }: { query: string, apiToken: string}) {
  return fetch(`/api/upload/search?query=${encodeURIComponent(query)}`, {
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
  }).then(async (response) => {
    try {
      if (response.ok) {
        const data = await response.json();
        return data
      } else {
        throw new Error(`HTTP Error: ${response.status}`);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  });
}

type Candidate = {
  entityId: number,
  entity: string
  reference: string
  parentReference?: string
  name?: string
  score: number
  path: PathNode[]
}

type PathNode =  {
  reference?: string,
  entityId?: number,
  entity: string,
  name?: string
}

async function uploadFile(c: Candidate, apiToken: string, formData: FormData) {
    const params = { entityId: "" + c.entityId, entity: c.entity, reference: c.reference};
    if (c.parentReference) {
      params['parentReference'] = c.parentReference;
    }
    if (c.name && (c.entity === patent_family || c.entity === trademark_family)) {
      params['name'] = c.name;
    }

    return fetch(`/api/upload/upload?${createSearchParams(params)}`, {
      method: "POST",
      headers: { Authorization: `Bearer ${apiToken}`, Accept: "application/json" },
      body: formData,
    });
}

function SavePlugin({apiToken, setMessage, goToSettings}: {apiToken: string, setMessage: (message: Message) => void, goToSettings: () => void}) {
  const item = Office.context.mailbox.item;

  const [isLoading, setIsLoading] = useState(false)
  const [candidates, setCandidates] = useState([] as Candidate[])
  const [candidate, setCandidate] = useState("0")

  const [query, setQuery] = useState('')
  const [lastPayload, setLastPayload] = useState(undefined)
  const hasUploaded = lastPayload !== undefined

  // assume to be true by default once we are here
  const [isAuthorized, setIsAuthorized] = useState(true)

  useEffect(() => {
    if (item) {
      const subject = item.subject
      searchForReferences({ query: subject, apiToken })
        .then(data => {
          if (data.status === 'ok' && data.payload) {
            setCandidates(data.payload)
          } else if (data.status === 'unauthorized') {
            setIsAuthorized(false)
          }
        })
        .catch(err => setMessage({ type: 'error', message: err.message }))
    }
  }, [apiToken, setMessage, item])

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const c = candidates[parseInt(candidate)];
      // error message if no candidate is selected

      const mimeMessage = await new Promise<string>((resolve, reject) => {
        item.getAsFileAsync(async (result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            resolve(result.value);
          } else {
            reject(result.error.message);
          }
        });
      });

      const formData = new FormData();
      const blob = base64ToBlob(mimeMessage, "message/rfc822");
      // only keep the following chars in filename: [a-zA-Z0-9 ._\-]*
      const filename = htmlEncode(item.subject ?? ("email" + item.dateTimeCreated.toISOString().slice(0, 19))); //.replace(/[^a-zA-Z0-9 ._\\-]/g, '');
      formData.append("file", blob, `${filename.trim()}.eml`);

      formData.append("path", JSON.stringify(c.path))

      const resp = await uploadFile(c, apiToken, formData);

      setIsLoading(false);

      const payload = await resp.json()

      if (payload.status === 'fail') {
        throw new Error(payload.message);
      }

      await addSavedCategory();

      // setMessage({ type: "info", message: "Email saved successfully!", payload });
      setLastPayload(payload);
    } catch (err) {
      setMessage({ type: "error", message: err.message });
    }
  }

  if (!item) return null

  return (
    <>
      {!isAuthorized && (
        <div className="py-2 mb-4 pl-4 border-l-4 border-amber-500 text-sm">
          It looks like your API Token is not valid. Please make sure to set the correct one in the{' '}
          <span className="underline cursor-pointer text-pcx-500" onClick={goToSettings}>
            settings
          </span>
          .
        </div>
      )}
      <form onSubmit={onSubmit} className="max-w-full mt-2 h-full">
        {/* {JSON.stringify(candidates, null, 2)} */}
        <label htmlFor="candidate" onClick={() => lastPayload && navigator.clipboard.writeText(lastPayload)} className="label mb-2">
          Save Email to
        </label>

        <Combobox
          value={query === '' ? { action: 'set', value: candidate } : { action: 'search'} as ComboboxAction}
          by={(a, b) => a.action === b.action && a.action === 'set' && b.action === 'set' && a.value === b.value}
          disabled={isLoading}
          onChange={(action: ComboboxAction) => {
            if (action.action === 'set') {
              setCandidate(action.value)
            } else if (action.action === 'search') {
              searchForReferences({
                query: query !== '' ? query : item.subject,
                apiToken,
              })
                .then(data => {
                  setCandidates(data.payload)
                  setQuery('')
                })
                .catch(err => setMessage({ type: 'error', message: err.message }))
            }
          }}
          as="div"
          className={clsx('relative', !isAuthorized && 'opacity-30')}
          id="candidate"
          name="candidate"
          immediate
        >
          <div className="relative">
            <ComboboxInput
              aria-label="Candidate"
              disabled={!isAuthorized}
              className="border border-slate-500 rounded w-full pl-3 pr-11 py-2 truncate text-ellipsis"
              displayValue={(action: ComboboxAction) => {
                const candidate = action.action === 'set' ? candidates[parseInt(action.value)] : undefined
                if (!candidate) return 'Loading...'
                return candidate.reference + (candidate.name ? ` - ${truncate(candidate.name)}` : '')
              }}
              onClick={e => e.currentTarget.select()}
              onChange={e => setQuery(e.target.value)}
            />
            <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
              <ChevronDownIcon className="size-6 fill-pcx-500 group-data-[hover]:fill-pcx-400" />
            </ComboboxButton>
          </div>
          <ComboboxOptions
            transition
            className={
              'absolute top-12 bg-white border border-pcx-400 rounded-lg shadow-lg min-w-full max-w-full h-fit overflow-hidden text-sm' +
              ' ' +
              'transition duration-20 ease-out data-[closed]:scale-95 data-[closed]:opacity-0'
            }
          >
            <ComboboxOption
              disabled={query === ''}
              className={clsx(
                'px-3 py-1.5 text-left w-full',
                query === '' ? 'text-slate-500' : 'hover:bg-pcx-400 hover:text-white cursor-pointer',
              )}
              key="search"
              value={{ action: 'search' }}
            >
              <MagnifyingGlassIcon className="size-4 inline mb-0.5" />{' '}
              {query === '' ? 'Type to search for a custom reference' : `Get new proposals for ${query}`}
            </ComboboxOption>
            {candidates.map((_candidate, i) => (
              <ComboboxOption key={i} value={{ action: 'set', value: i }}>
                {({ selected, focus }) => (
                  <div
                    className={clsx(
                      'max-w-full whitespace-nowrap truncate text-ellipsis pl-8 pr-3 py-1 text-left hover:bg-pcx-400 hover:text-white cursor-pointer relative',
                      focus && 'bg-pcx-200 hover:bg-pcx-400 hover:text-white',
                    )}
                  >
                    {selected && <CheckIcon className="size-4 absolute left-3 top-1.5" />}
                    {_candidate.reference} {_candidate.name ? ` - ${_candidate.name}` : ''}
                  </div>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>

        <button
          type="submit"
          disabled={isLoading || !isAuthorized}
          className="btn-secondary disabled:opacity-20 disabled:hover:bg-transparent disabled:hover:text-pcx-500 disabled:cursor-not-allowed w-full mt-4 py-2"
        >
          Save{' '}
          {isLoading ? (
            <IconSpinner className="inline align-top mt-1 ml-2 animate-spin size-4" />
          ) : hasUploaded ? (
            <IconCheck className="inline align-top mt-1 ml-2 size-4" />
          ) : (
            <ArrowUpTrayIcon className="inline align-top mt-1 ml-2 size-4" />
          )}
        </button>
      </form>
      <div id="response" />
    </>
  )
}

type ComboboxAction = { action: 'set', value: string } | { action: 'search' }

// Utility function to convert Base64 to a binary Blob
function base64ToBlob(base64, contentType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

const apiSettingsUrl = "/settings/user/api-token"

function Welcome({onSubmit}: {onSubmit: (apiToken: string) => void}) {
  return (
    <>
      <h2 className="text-2xl font-semibold mt-4 mb-3 text-pcx-700">
        Patent Cockpit Plugin
      </h2>
      <div className="text-slate-800 space-y-2 mb-4">
        {/* <p> Welcome to the Patent Cockpit Plugin!</p> */}
        <p>Save your emails straight to the document management system.</p>
        <p className="text-pcx-700 font-medium">
          To get started, <a href={apiSettingsUrl}
          className="underline"
          rel="noopener noreferrer"
          target="_blank"
          >generate an API Token <IconExternalLink className="size-5 inline align-baseline ml-1"/></a> and add it here.</p>
      </div>
      <SettingsForm {...{ onSubmit, apiToken: undefined, canDelete: false }} />
    </>
  );
}

function SettingsForm({onSubmit, apiToken, canDelete}: {onSubmit: (apiToken: string) => void, apiToken: string | undefined, canDelete: boolean}) {
  const [value, setValue] = useState(apiToken ?? '')

  return (
    <>
      <form
        id="settings-form"
        className="flex flex-col gap-2 w-full max-w-md"
        onSubmit={e => {
          e.preventDefault()
          onSubmit(value)
        }}
      >
        <div className="flex flex-row justify-between items-baseline">
          <label htmlFor="api-key" className="label">
            API Key
          </label>
          {canDelete && (
            <button
              type="button"
              className="font-medium text-xs text-slate-600 hover:text-red-600"
              onClick={() => {
                setValue('')
                onSubmit('')
              }}
            >
              Delete API Key
            </button>
          )}
        </div>
        <input
          type="password"
          id="api-key"
          name="api-key"
          className="w-full form-input"
          required
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <input type="submit" className="btn-secondary" value="Save API Key" />
      </form>
      <p className="mt-6 text-slate-500">
        Get your API Key from the{' '}
        <a href={apiSettingsUrl} className="underline" rel="noopener noreferrer" target="_blank">
          settings page
        </a>{' '}
        in Patent Cockpit.
      </p>
    </>
  )
}



/* CATEGORIES */

// must be in a function
function getSavedCategory() {
  return {
    displayName: "Saved in Patent Cockpit",
    color: Office.MailboxEnums.CategoryColor.Preset22,
  };
}

async function ensureCategoryExists() {
  const savedCategory = getSavedCategory();
  const existing = await findCategory(savedCategory);
  if (!existing) {
    await addCategoriesToMasterList([savedCategory]);
  }
}

async function findCategory(category: Office.CategoryDetails | string) {
  const displayName = typeof category === "string" ? category : category.displayName;

  return new Promise<Office.CategoryDetails>((resolve, reject) =>
    Office.context.mailbox.masterCategories.getAsync(function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        reject(asyncResult.error.message);
      } else {
        const masterCategories = asyncResult.value;
        const foundCategory = masterCategories.find((c) => c.displayName === displayName);
        resolve(foundCategory);
      }
    })
  );
}

async function addCategoriesToMasterList(categories: Office.CategoryDetails[]) {
  return new Promise<void>((resolve, reject) => {
    Office.context.mailbox.masterCategories.addAsync(categories, function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve();
        } else {
          reject('Cannot categories automatically. Add the following categories manually to remove the error: ' 
            + categories.map(c => '"' + c.displayName + '"').join(', ') + ".\n" + asyncResult.error.message);
        }
      });
  });
}

async function addSavedCategory() {
  const savedCategory = getSavedCategory();
  addCategoriesToItem(Office.context.mailbox.item, [savedCategory.displayName]);
}

async function addCategoriesToItem(item: Office.MessageRead, categories: string[]) {
    return new Promise<void>((resolve, reject) => {
        item.categories.addAsync(categories, function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve();
            } else {
                reject(asyncResult.error.message);
            }
        });
    });
}
/*
 Plan
[x] Check if category already exists and if not add it
[x] After saving email, add category to email
*/

/* END CATEGORIES */