
export function htmlEncode(str?: string) {
    if (typeof str !== 'string') return str

    // @ts-ignore
    return str.replace(/[<>?/:"*|\\]/gim, function(i) {
       return '&#' + i.charCodeAt(0) + ';';
    });
}

export function htmlDecode(str?: string) {
    if (typeof str !== 'string') return str

    return str.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
    });
}